import cmpTpl from './cmp.html';

//this file manages CMP widget rendering and button interactions
export default ((onAcceptCallback, onRefuseCallback) => {

    const WIDGET_WRAPPER_SELECTOR = '#cmp-widget-wrapper';
    const BTN_OPTIONS_SELECTOR = '.cmp-buttons-table-td-btn-options';
    const BTN_ACCEPT_SELECTOR = '.cmp-buttons-table-td-btn-accept';

    const init = () => {
        //generate CMP widget template
        const template = cmpTpl(window.inx.cmp);

        //add it to current document
        var widgetEl = document.createElement('div');
        widgetEl.innerHTML = template.trim();
        document.body.appendChild(widgetEl);

        const acceptBtn = document.querySelector(BTN_ACCEPT_SELECTOR);
        const optsBtn = document.querySelector(BTN_OPTIONS_SELECTOR);
        const widgetWrapperEl = document.querySelector(WIDGET_WRAPPER_SELECTOR);

        const hideModal = () => {
            widgetWrapperEl.style.display = 'none';
        };

        const addAcceptAllClickHandler = () => {
            acceptBtn.onclick = () => {
                // console.log('accept all button clicked!');
                //accepted: hide modal and set euconsent to accept all
                widgetWrapperEl.style.display = 'none';
                hideModal();

                onAcceptCallback();
            };
        };

        const addViewOptionsClickHandler = () => {
            optsBtn.onclick = () => {
                // console.log('view options button clicked');
                widgetWrapperEl.setAttribute('data-dark', 1);

                optsBtn.textContent = optsBtn.getAttribute('data-text');

                //add refuse handler to opts btn
                optsBtn.onclick = () => {
                    const marketingCookiesAllowed = document.querySelector('input[name="marketing_cookies"]').checked;
                    if (marketingCookiesAllowed) {
                        // console.log('accept selected clicked with marketing- allow all');
                        //if marketing checkbox checked, sill accept all
                        onAcceptCallback();
                    } else {
                        // console.log('accept selected clicked without marketing- refuse all');
                        //refuse only on end screen when clicked "Accept selected" button
                        //refuse CMP if unchecked marketing checkbox
                        onRefuseCallback();
                    }
                    hideModal();
                };

                acceptBtn.textContent = acceptBtn.getAttribute('data-text');
            };
        };

        //add btn click handlers
        addAcceptAllClickHandler();
        addViewOptionsClickHandler();
    };

    init();
});
