/* global INBOX */
import initials from 'initials';

import { isStorageAvailable } from '../utils';
import defaultAvatarImg from 'src/img/misc/person.png';
import inx from 'inx';

const getInitials = (str) => {
    const result = initials(str);
    return result[0] + result.slice(-1);
};

// XXX: Refactor this someday
INBOX.namespace('classes.Avatar', function () {
    const spacerImgUrl = `${location.protocol}//${location.host}/assets/com/img/spacer.gif`;
    const isLSAvailable = isStorageAvailable('localStorage') && localStorage !== null;
    let iName = '.inx-profile__thumb';
    let instance = null;

    function doReload(url) {
        var deferred = $.Deferred();
        if (mustReloadCache()) {
            img.reloadCached(url).always(function () {
                // console.log('cache reloaded! Fetching img...');
                img.fetch(url).then(deferred.resolve, deferred.reject);
            });
        }
        else {
            img.fetch(url).then(deferred.resolve, deferred.reject);
        }
        return deferred.promise();
    }

    function mustReloadCache() {
        if (isLSAvailable) {
            // Todo: this probably does not work if multiple avatars are on page (linked accounts)
            return (localStorage.reloadCachedImg === 'true') ? true : false;
        }
        return true;
    }

    // Set redirect url in case of empty avatar
    function setLinkToAvatar(atag, avatarInstance) {
        avatarInstance.isSet.done(function (type) {
            if (type !== 'default') {
                return;
            }
            $(atag.linkId).attr('href', atag.addUrl);
        });
    }

    const img = {
        // Spacer dimensions are: 1 x 1px
        isSpacer(img) {
            return (img.height < 2 && img.width < 2);
        },
        // Fetch image
        fetch(iSrc) {
            const deferred = $.Deferred();

            if (!iSrc) {
                deferred.reject();
            }
            else {
                const pic = new Image();
                pic.addEventListener('load', function () {
                    // console.log('Image loaded: %s', this.src);
                    if (img.isSpacer(this)) {
                        // console.info('image is spacer');
                        deferred.reject();
                    }
                    else {
                        deferred.resolve();
                    }
                });
                pic.addEventListener('error', function () {
                    // console.warn('Failed to load: %s', this.src);
                    deferred.reject();
                });
                pic.src = iSrc;
            }
            return deferred.promise();
        },
        getUrl(selector) {
            let url = null;
            const elm = document.querySelector(selector);
            if (!elm) return url;

            if ('src' in elm.dataset) {
                url = elm.dataset.src.trim();
                delete elm.dataset.src;
            }

            return url;
        },
        // Reload img from server
        reloadCached(url) {
            url = url || instance.imgUrl;
            let deferred = $.Deferred(),
                firstLoad = true,
                iframe;

            function loadCallback() {
                if (firstLoad) {
                    firstLoad = false;
                    iframe.src = url; // Avoid 'Permission denied in IE 11
                    iframe.contentWindow.location.reload(true);
                    if (isLSAvailable) {
                        localStorage.reloadCachedImg = 'false';
                    }
                }
                else {
                    if (iframe.parentNode) {
                        iframe.parentNode.removeChild(iframe);
                    }
                    deferred.resolve();
                }
            }

            iframe = document.createElement('iframe');
            iframe.style.display = 'none';
            document.body.appendChild(iframe);
            iframe.addEventListener('load', loadCallback, false);
            iframe.addEventListener('error', loadCallback, false);
            iframe.src = url;
            return deferred.promise();
        },
        /** Set avatar or default image
        * @param {object} iObj Contains img name, type and url
        */
        set(iObj = {}) {
            // console.log(iObj);
            const { user = {} } = iObj;
            const selector = iObj.iName || iName;
            const imgUrl = iObj.iUrl || spacerImgUrl;
            const imgType = iObj.iType || 'default';
            const items = document.querySelectorAll(selector);

            items.forEach((item) => {
                const itemParent = item.parentNode;

                if (itemParent?.classList?.contains('user-img')) {
                    const title = `${user.name}\u000A${user.email}`;
                    itemParent.setAttribute('title', title);
                }

                if (imgType === 'default') {
                    if ('initials' in item.dataset) {
                        const userInitials = getInitials(user.name) === 'undefined'
                            ? getInitials(user.email)
                            : getInitials(user.name);
                        const span = document.createElement('span');
                        span.classList.add('rounded-circle', 'user-initials');

                        if (userInitials === 'undefined' || userInitials === undefined) {
                            span.innerHTML = '<span class="ifi-profile"></span>';
                        } else {
                            span.textContent = userInitials;
                        }

                        item.replaceWith(span);
                    } else {
                        if (!item.classList.contains('ifi-profile')) {
                            item.style.backgroundImage = `url(${defaultAvatarImg})`;
                        }
                    }
                } else {
                    item.classList.replace('ifi-profile', 'img-thumbnail');
                    item.style.cssText = `background-image:url(${imgUrl});background-size:100%`;
                }
            });

            return imgType;
        }
    };

    /**
    * Constructor for avatar checking/setting/reloading
    * @this Avatar
    * @constructor
    * @param {string} name Avatar img selector name (class || id)
    */
    const Avatar = function (selector, user) {
        iName = selector || iName;
        // Current URL of avatar
        this.imgUrl = img.getUrl(iName);
        this.iNameX = iName;
        // Profile img set status promise
        this.isSet = $.Deferred();

        this.check = () => {
            doReload(this.imgUrl).done(() => {
                this.isSet.resolve(img.set({
                    user,
                    iType: 'setByUser',
                    iName: this.iNameX,
                    iUrl: this.imgUrl
                }));
            }).fail(() => {
                this.isSet.resolve(img.set({
                    user,
                    iType: 'default',
                    iName: this.iNameX
                }));
            });

            return this;
        };

        this.reload = img.reloadCached;
        this.removeIt = img.set;
    };

    return function (selector, userData) {
        // console.log(inx.params.user);
        const user = userData || inx.params.user;
        instance = new Avatar(selector, user);

        // Redirect Link for empty profile img
        if (user && user.profileImg) {
            setLinkToAvatar(user.profileImg, instance);
        }
        return instance;
    };
});
