import lineClamp from '../modules/line-clamp';
import { MEDIA_QUERIES } from '../config/constants';

const cachedTemplate = (() => {
    let cachedTemplates = {};
    return (name, data) => {
        if (!cachedTemplates[name]) {
            cachedTemplates[name] = new Template(data);
        }
        return cachedTemplates[name];
    };
})();

const doAjax = args => {
    let opts = {
        xhrFields: { withCredentials: true }
    };
    if (typeof (args) === 'object') {
        opts = { ...opts, ...args };
    }
    return $.ajax(opts).promise();
};

// Parse html templates
function Template (data) {
    return (options, escaped) => {
        let html = data;
        $.each(options, (k, v) => {
            html = html.replace(new RegExp('%' + k + '%', 'g'), escaped ? v : escapeHtml(v));
        });
        return html;
    };
}

/*
*  str byteToHex(uint8 byte)
*  converts a single byte to a hex string
*/
function byteToHex(byte) {
    return ('0' + byte.toString(16)).slice(-2);
}

function escapeHtml(text) {
    const map = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#039;'
    };
    return text.replace(/[&<>"']/g, function (m) {
        return map[m];
    });
}

function getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

function getRndHash(len, pref) {
    len  = len || 14;
    pref = pref || '';
    const
        crypto = window.crypto || window.msCrypto,
        arr    = new Uint8Array(len / 2);
    crypto.getRandomValues(arr);
    return pref + [].map.call(arr, byteToHex).join('');
}

function isObjectEmpty(obj) {
    return (Object.getOwnPropertyNames(obj).length === 0);
}

/*
* Detect if localstorage available and accessible.
* Proper way to detect "access denied" in IE != Edge
*
* Reference: https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
*
* @param {string} type One of: localStorage, sessionStorage
*/
function isStorageAvailable(type) {
    try {
        const storage = window[type],
            x = '__storage_test__';
        storage.setItem(x, x);
        storage.removeItem(x);
        return true;
    }
    catch (e) {
        return false;
    }
}

// Test whether email looks valid
function looksLikeValidEmail (str) {
    var rex = /^".+"\s?<(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)@(([a-z\-0-9]+\.)+[a-z]{2,}))>$/i;
    return rex.test(str);
}

//  It is needed, becauce IE < 11 does not have it
function getLocOrigin() {
    if (!window.location.origin) {
        window.location.origin = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
    }
    return window.location.origin;
}

class PrivateData {
    constructor(init) {
        this._wm = new WeakMap(init);
    }
    clear() {
        this._wm = new WeakMap();
    }
    delete(k) {
        return this._wm.delete(k);
    }
    get(k) {
        return this._wm.get(k);
    }
    has(k) {
        return this._wm.has(k);
    }
    set(k, v) {
        this._wm.set(k, v);
        return this;
    }
}

// Inbox breakpoints list
function getBreakPointList(name) {
    const bpList = {
        sbv2: {
            xxl: 2560,
            xl:  1920,
            lg:  1700,
            md:  1260,
            sm:  960,
            ms:  720,
            xs:  300
        },
        sb: {
            xxl: 2560,
            xl:  1920,
            lg:  1440,
            md:  1260,
            sm:  960,
            ms:  720,
            xs:  300
        },
        default: {
            xxl: 1920,
            xl:  1440,
            lg:  1260,
            md:  960,
            sm:  720,
            ms:  480,
            xs:  300
        }
    };
    return (name && bpList[name] ? bpList[name] : bpList.default);
}

function getBreakPoints(name) {
    const
        // Class "sb" is default, because it may be added in portal <body> tag by the banner system
        className = name || 'sb',
        bodyHasClass = $('body').hasClass(className) ? className : undefined;

    return getBreakPointList(bodyHasClass);
}

function multilineEllipsis(elm, lineCount) {
    if (typeof (elm) === 'string') {
        elm = document.querySelector(elm);
    }
    lineClamp(elm, lineCount);
}

function setSelectorClass(selector, prefix) {
    return (cName) => {
        const classRegex = new RegExp('\\b' + prefix + '_\\w{2,3}', 'i');

        $(selector)
            .removeClass((i, cName) => classRegex.test(cName) ?
                cName.match(classRegex)[0]
                :
                false
            )
            .addClass(`${prefix}_${cName}`);
    };
}


const getDeviceGroup = ((mqList) => {
    const
        bpNames = Object.keys(mqList),
        matched = bpNames.find((item) => window.matchMedia(`screen and ${mqList[item]}`).matches);
    return () => matched || false;
})(MEDIA_QUERIES);

// Search key in object tree
// http://www.mikedoesweb.com/2016/es6-depth-first-object-tree-search/
const searchKeyInTree = (needle, haystack, found = []) => {
    Object.keys(haystack).forEach((key) => {
        if (key === needle) {
            found.push(haystack[key]);
            return found;
        }
        if (typeof haystack[key] === 'object') {
            searchKeyInTree(needle, haystack[key], found);
        }
    });
    return found;
};

// Remove leading and trailing slashes
const trimSlashes = (arg) => {
    if (typeof(arg) !== 'string') {
        return;
    }
    return arg.replace(/^\/+|\/$/g, '');
};

export {
    cachedTemplate as getTemplate,
    doAjax,
    escapeHtml,
    getBreakPointList,
    getBreakPoints,
    getDeviceGroup,
    getLocOrigin,
    getRandomInt,
    getRndHash,
    isObjectEmpty,
    isStorageAvailable,
    looksLikeValidEmail as isValidEmail,
    multilineEllipsis,
    PrivateData,
    searchKeyInTree,
    setSelectorClass,
    Template,
    trimSlashes
};
