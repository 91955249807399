import transparentPixelSrc from 'src/img/public/spacer.gif';

const tpl = new Map();

tpl.set('item', (args) => {
    const { aHref, imgId, data, imgSrc } = args;
    const { email, name } = data;

    return `
        <li class="account">
            <a
                class="btn btn-brand-dark w-100 text-start px-0 account__link"
                href="${aHref}"
                title="${name}"
            >
                <span class="account__avatar-wrap">
                    <img
                        alt="Avatar"
                        class="rounded-circle"
                        data-initials="true"
                        data-src="${imgSrc}"
                        id="${imgId}"
                        src="${transparentPixelSrc}"
                    />
                    <i class="indicator" data-user="${email}"></i>
                </span>
                ${email}
            </a>
        </li>
    `;
});

tpl.set('itemsList', (items) => `
    <ul class="linked-accounts-list">
        ${items}
    </ul>
`);

tpl.set('addAcount', (aHref, i18n) => `
    <div id="add-account" class="account">
        <a
            class="btn btn-brand-dark py-2 px-0 w-100 text-uppercase text-center"
            href="${aHref}"
        >
            <span class="ico ipi-plus"></span>
            ${i18n}
        </a>
    </div>
`);

export default tpl;
