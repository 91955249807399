import '../utils/element-closest'; // For IE 11 only
import { createPopper } from '@popperjs/core';

const positionLangDropDown = ({ target }) => {
    const dropDown = target.parentNode.querySelector('.dropdown-menu');
    const icon = target.querySelector('.ico-lang');

    createPopper(icon, dropDown, {
        placement: 'bottom',
        modifiers: [
            {
                name: 'flip',
                options: { fallbackPlacements: ['bottom-start'] }
            },
            {
                name: 'offset',
                options: { offset: [-3, 18] }
            },
        ]
    });
};

const goToLanguage = (lang) => {
    const url = new URL(location.href);
    const params = new URLSearchParams(url.search);
    params.set('language', lang);
    params.set('persist_language', 1);
    url.search = params.toString();
    location.href = url.toString();
};

// Provides visual feedback while loading new language
const changeLanguage = (e, switcher) => {
    const liTag = e.target.closest('li');

    if (!liTag) return;

    const icon = switcher.querySelector('.ico-lang');
    const newLang = liTag.dataset.lang;
    const oldLang = icon.className.match(/\bflag-\w{2}/)[0];

    icon.classList.remove(oldLang);
    icon.classList.add(`flag-${newLang}`);

    goToLanguage(newLang);
};

export default (selector) => {
    const container = document.getElementById(selector);
    if (!container) return;

    const elm = {
        switcher: container.querySelector('.language-switch'),
        dropdown: container.querySelector('.dropdown-menu')
    };

    container.addEventListener('shown.bs.dropdown', positionLangDropDown);
    elm.dropdown.addEventListener('click', (e) => changeLanguage(e, elm.switcher));
};
