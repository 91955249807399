/* global INBOX */
/* eslint-disable */
import $ from 'jquery';
import 'jquery-touchswipe';

// Workaround for sidebar on small screens.
// If sidebar height is bigger than viewport, add vertical scroll
function checkSidebarScroll(){
    const sideNav = $('#side-nav').height();
    if ((sideNav + 100) > $(window).height()) {
        $('#side-menu .side-wrapper').css('overflow-y', 'auto');
        $('#side-menu .side-wrapper').css('overflow-x', 'hidden');
    }
    else {
        $('#side-menu .side-wrapper').removeAttr('style');
    }
}

// Info: This function is global and is called from banner system
window.affixTop = offTop => {
    //portal specific code
    if (inx && inx.portal && inx.portal.refocus_login_form !== 'undefined') {
        inx.portal.refocus_login_form();
    }
};

// Remove body scroll when modal window or sidebar toolbar is called (workaround for ios scroll bug)
//todo remove PORTAL-437 code if there are no such problems on iOS anymore
function disableBodyScroll() {
    var $headerEl = $('#header');
    var ycoord = $(window).scrollTop();
    $headerEl.data('ycoord', ycoord);
    ycoord = ycoord * -1;
    $('body').css({
        // 'position': 'fixed',
        'left': '0',
        'right': '0',
        'top': ycoord + 'px'
    });
    $('footer').css('position', 'initial');

    //Keeps toolbar header visible when opening modal window
    if ($headerEl.hasClass('affix')) {
        $headerEl.addClass('affix-under').data('hidden', false);
    }
    //Keeps toolbar header hidden when opening modal window
    if ($headerEl.hasClass('hide-up')) {
        $headerEl.removeClass('affix-under').data('hidden', true);
    }
}
//Reset body scroll when modal window or sidebar toolbar is closed
function resetBodyScroll() {
    var $headerEl = $('#header');
    $('body').css({
        // 'position': '',
        'left': '',
        'right': '',
        'top': ''
    });
    $('footer').css('position', '');
    $(window).scrollTop($headerEl.data('ycoord'));
    //Keep toolbar header visible when closing modal window
    if ($headerEl.data('hidden') === false) {
        setTimeout(function() {
            $headerEl.removeClass('hide-up');
            $headerEl.removeClass('affix-under');
        }, 500);
    }
    //Keep toolbar header hidden when closing modal window
    if ($headerEl.data('hidden') === true && $headerEl.data('ycoord') > 0) {
        $headerEl.css('top', '-100px').addClass('hide-up').data('hidden', false);
    }
}

function gaSideMenuSend(action) {
    if (window.ga) {
        window.ga('otherTracker.send', 'event', 'Toolbar', action, 'vertical', 1);
    }
}

//Hide toolbar header on scroll down, show when scrolling up (only for screensize under 960px)
var didScroll;
var lastScrollTop = 0;
var delta = 5;
var navbarHeight = $('#header').outerHeight();

$(window).scroll(function () {
    didScroll = true;
});

setInterval(function () {
    //Run function only if window width smaller than 960px
    if (didScroll && (window.matchMedia('(max-width: 959px)').matches)) {
        hideHeaderOnScroll();
        didScroll = false;
    } else if (window.matchMedia('(min-width: 960px)').matches) {
        $('#header').css('top', '').removeClass('hide-up');
    }
}, 100);

function hideHeaderOnScroll() {
    var st = $(this).scrollTop();

    // Make sure they scroll more than delta
    if (Math.abs(lastScrollTop - st) <= delta)
        return;

    // This is necessary so you never see what is "behind" the navbar.
    if (st > lastScrollTop && st > (navbarHeight + 500)) {
        // Scroll Down
        $('#header').addClass('hide-up').data('hidden', true);
    } else {
        // Scroll Up
        if (st + $(window).height() < $(document).height()) {
            $('#header').css('top', '0').removeClass('hide-up');
        }
    }
    lastScrollTop = st;
}

$(function () {
    if (!$('.modal')) {
        return;
    }
    $('.modal').on('shown.bs.modal', function()  { // any time a modal is shown
        if (window.history.pushState != null) {
            var urlReplace =  ''; //"#" + $(this).attr('id'); // make the hash the id of the modal shown
            window.history.pushState(null, null, urlReplace); // push state that hash into the url
        }

    });

    // If a pushstate has previously happened and the back button is clicked, hide any modals.
    $(window).on('popstate', function() {
        $('.modal').modal('hide');
    });

    // Sidebar menu trigger Functions
    // Get browser scrollbar width
    const
        scrollbarWidth = $(window).outerWidth() - $(window).innerWidth(),
        backdropTemplate = "<div class='modal-backdrop' />";

    //is superuser clicking profile redirects to pass change so support has easier access to it
    if (inx && inx.params.isSuper) {
        const headerNavItem = document.querySelector('#header-nav-user');
        if (headerNavItem) {
            headerNavItem.onclick = (e) => {
                e.preventDefault();
                if (document.querySelector('.user-detail')) {
                    window.location = document.querySelector('.user-detail').getAttribute('data-profileoverviewurl');
                }
            };
        }
    }
    const addSelector = inx && inx.params.isSuper ? '' : ',#header-nav-user';

    $('#side-trigger'+addSelector).on('click', function() {
        INBOX.observer.notify('sideMenuOpen', true);

        if (window.history.pushState != null) {
            window.history.pushState(null, null, '');
        }

        gaSideMenuSend('Open');

        $('body')
            .toggleClass('side-open')
            .css('padding-right', scrollbarWidth)
            .append(backdropTemplate);
        //$("footer").css("padding-right", scrollbarWidth);
        disableBodyScroll();

        $(window).on('popstate', function() {
            if ($('#close-trigger').is(':visible')) {
                $('#close-trigger').trigger('click');
            }
        });

        // Close Menu Trigger Function
        $('#close-trigger, .modal-backdrop, .side-menu-close').on('click', function() {
            INBOX.observer.notify('sideMenuOpen', false);

            $('body').removeClass('side-open').css('padding-right', '');
            $('.modal-backdrop').remove();
            //$("footer").css("padding-right", "");
            resetBodyScroll();
            gaSideMenuSend('Close');

        });

        $(document).on('keyup',function(evt) {
            if (evt.keyCode == 27) {
                $('body').removeClass('side-open').css('padding-right', '');
                $('.modal-backdrop').remove();
                //$("footer").css("padding-right", "");
                resetBodyScroll();
                gaSideMenuSend('Close');
            }
        });
    });

    // Sidebar swipe left to close sidebar
        if (inx.params.isMobile) {
            $('#side-menu').swipe({
                // Generic swipe handler for all directions.
                // Documentation: https://github.com/mattbryson/TouchSwipe-Jquery-Plugin
                swipe: function (event, direction, distance, duration, fingerCount, fingerData) {
                    if (direction == 'right') {
                        $('body').append("<div class='modal-backdrop'></div>").toggleClass('side-open');
                        $('.modal-backdrop').remove();
                        resetBodyScroll();
                        gaSideMenuSend('Open');
                        return false;
                    }
                }
            });

        $('#side-menu').swipe({fingers: 1, allowPageScroll: 'vertical'});
    }

    //actual code execution
    checkSidebarScroll();

    // Window events
    var $header = $('#header');
    if ($header.length) { // NOTE: We have pages without #header
        $(window).resize(function(){
            var offTop = $header.offset().top;
            checkSidebarScroll();
        });
        // element events
        // affix menu fix when on top
        $header.on( 'affix.bs.affix', function() {
            if ( !$( window ).scrollTop() ) {
                return false;
            }
        });
    }

    // allow menu links inside dropdowns be clickable
    $('body').on('touchstart.dropdown click', '#side-nav .sidebar-dropdown li a, #product-menu .inx-product-dropdown li a, #toolbar-dropdown li a, #toolbar-dropup li a', function (e) {
        e.preventDefault();
        window.location.href = $(this).attr('href');
    });

    // Call some functions on modal open/close
    $('body')
        .on('shown.bs.modal', function () {
            disableBodyScroll();
        })
        .on('hidden.bs.modal', function () {
            resetBodyScroll();
        });
});
