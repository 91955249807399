import './modules/avatar';
import './oldschool/toolbar_new';

import { getBreakPoints as currentBreakPoints } from './utils';
import { getMailUrls } from './config/';
import alertTpl from './views/alert.html';
import bisquit from './utils/cookie-handler';
import cmp from './modules/cmp/index';
import initLanguageSwitcher from './modules/language-switcher';
import initLinkedAccounts from './modules/linked-accounts';
import mailBadgesInit from './modules/mail-badges';
import mailFetch from './utils/mail-fetch';
import mailPlusPromo, { MODAL_TYPE_ADBLOCK } from './modules/mailplus-promo';
import ProdBar from './modules/products-bar';
import setCustomLogo from './modules/custom-logo';
import showWeather from './modules/weather';

// global vars for fnErrorTrap (used in mail at least)
let js_context = null;
let js_now = new Date();
let js_started = Math.round(js_now.getTime() / 1000);

const initProdBar = (opts) => {
    const
        {
            prodListID,
            lowestWidth
        } = opts,
        $prodList = $(prodListID);

    if ($prodList.length < 1) {
        return;
    }
    // Do not show horizontal products bar on devices with small screens
    if ($(window).width() < lowestWidth) {
        $prodList.parent().hide();
        return;
    }

    new ProdBar(opts).render();
};

const fetchMailData = (INBOX, params, badgeOpts) => {
    mailBadgesInit(badgeOpts);
    mailFetch(INBOX, params);
};

const initToolbar = (INBOX, inx) => {
    const bPoints = currentBreakPoints();
    const {
        i18n,
        params,
        sideMenu,
        toolBar: {
            i18n: tbi18n,
            selectors: tbSelectors
        }
    } = inx;
    const {
        mailHost,
        mapiHost,
        mapiFetchInterval,
        project,
        user,
        weather
    } = params;
    const { asideID, headerID, prodBarID } = tbSelectors;
    // Defaults for mail badges
    const badgeOpts = {
        i18n: {
            recent: tbi18n.recentMessages,
            unread: tbi18n.unreadMessages
        },
        links: getMailUrls({
            mapiHost,
            mailHost,
            userMail: user.email
        }),
        selectors: [asideID, headerID, prodBarID].join(',')
    };
    // Defaults for mail fetching
    let mailFetchParams = {
        mailHost,
        mapiFetchInterval,
        mapiHost,
        userMail: user.email
    };

    return () => {
        setCustomLogo(tbSelectors.headerID, tbSelectors.siteLogo);

        if (user.email && user.email.length > 0) {
            // Set avatar
            // const avatar = INBOX.classes.Avatar();
            // avatar.check();
            var avatar1 = INBOX.classes.Avatar('.inx-profile__thumb');
            avatar1.check();

            // Fetch mail data and show mail badges
            // For portal we also have to fetch mail bodies
            if (['go', 'portal'].indexOf(project.name) !== -1) {
                mailFetchParams = {
                    ...mailFetchParams,
                    fetchMailBodies: true
                };
            }

            if (typeof mailFetchParams.mailHost !== 'undefined') {
                const header = document.querySelector(tbSelectors.headerID);

                if (header && (
                    user.status === undefined
                    || user?.status?.trim() === ''
                    || ['open', 'trial'].includes(user.status)
                )) {
                    fetchMailData(INBOX, mailFetchParams, badgeOpts);
                }
            }
        }

        // Initialize multi account module
        if (sideMenu?.linkedAccounts) {
            const mainUser = { email: user.email, name: user.name };
            initLinkedAccounts({ ...sideMenu, mapiHost, mainUser }, INBOX);
        }

        // Initialize horizontal products bar
        initProdBar({
            align: params.productBar.itemsAlign,
            lowestWidth: bPoints.sm,
            moreItemsCountID: '#product-count',
            moreMenuID: '#product-menu__more',
            prodListID: tbSelectors.prodBarID
        });

        // Weather in the header
        if (weather && weather.enabled) {
            showWeather(tbSelectors.weather, weather, i18n.weather);
        }

        // Language switcher in the header
        initLanguageSwitcher(tbSelectors.langSwitch);
    };
};

((win) => {
    const { document: doc, INBOX, inx, inxBX } = win;
    const { ads, params } = inx;

    doc.addEventListener('DOMContentLoaded', initToolbar(INBOX, inx));

    // MAil+ promo modal
    if (typeof (inxBX) === 'object') {
        const { mailType, isSuper, project: { name: projectName, product: productName } } = params;

        ads.prodPromoPrepare.resolve({
            callBack: mailPlusPromo,
            paths: {
                ...ads.assets,
                sharedAssetsPath: params.standard.path
            }
        });

        const canShowAdblockModal = () => {
            //bannerka loaded correctly, do not show adblock modal
            if (inxBX.failed !== true) {
                return false;
            }
            //support (superuser) has impersonated user, do not show adblock modal
            if (isSuper) {
                return false;
            }
            //go.inbox.lv is landing page for premium users. do not show banner there
            if (productName === 'go') {
                return false;
            }
            //adblock modal is show only in portal and mail projects
            if (projectName !== 'portal' && projectName !== 'mail') {
                return false;
            }

            if (projectName === 'mail') {
                // Do not show adblock when "mailType" is not defined.
                // E.g. - error pages (400, 404, etc.)
                if (!mailType) return false;
                // Do not show for mail+ users
                if (mailType.isMailPlus) return false;
            }

            // Do not show Adblock modal in case of purchase modal
            const params = (new URL(location.href)).searchParams;
            const showPurchaseForm = params.get(ads.purchaseFormQueryParam);
            if (showPurchaseForm) {
                return false;
            }

            //if user was logged in via this device for last 30 days as mail+ user
            //cookie is set in login for mail+ users in non-bizmail domains
            const mailPlusUserWasLoggedIn = bisquit.getIt('is_premium');
            if (mailPlusUserWasLoggedIn) {
                return false;
            }

            return true;
        };
        // Show "AdBlock detected" modal
        //do not show for superuser (support) and if user has mail+
        if (canShowAdblockModal()) {
            inx.ads.prodPromo({
                cookieName: 'adBlock-modal',
                i18n: inx.i18n.blockedAds,
                modalType: MODAL_TYPE_ADBLOCK,
                ttl: { close: 1800, later: 1800 } // 30 min. both
            });
        }
    }

    cmp();

})(window);

// Deprecated
window.inx.v6 = (() => {
    return {
        fnErrorTrap(sMsg, sUrl, sLine) {
            if (!window.enable_jserror_logging) {
                return true;
            }
            if (js_context) sUrl += '&JS_CONTEXT=' + js_context;
            var js_now = new Date();
            var url = '/jserror.php?error=JSERROR : ' + escape(sMsg) + '&line=' + sLine + '&sec=' + (Math.round(js_now.getTime() / 1000) - js_started) + '&method=GET&url=' + escape(sUrl) + '&rand=' + Math.random();
            var im = new Image();
            im.src = url;
            return true;
        },

        // TODO: Get rid of this method!
        showFlashMessage(type, message, append, containerID) {
            let template = $('#alert-template'),
                opts = {
                    dismissible: true,
                    hasIcon: true,
                    text: message,
                    type: type || 'info'
                },
                container = $('#flash-messages');

            if (container.length > 0 && template.length > 0) {
                let newMessage = $(
                    template.prop('outerHTML').replace('%TYPE%', type).replace('%MESSAGE%', message))
                    .removeAttr('id')
                    .show();

                if (!append) {
                    container.children().not(template).remove();
                }
                container.show().append(newMessage);
            }
            else {
                $(containerID).show().html(alertTpl(opts));
            }
        }
    };
})();
