/**
 * == CMP ==
 * original script: https://github.com/Ldebost/cmp_pv/tree/tcf_v2
 * this script is trimmed down original script
 *
 * better alternative: https://github.com/InteractiveAdvertisingBureau/Consent-String-SDK-JS
 * but it lacks google ac custom stuff:
 * googleAc is simpler as it has format: 1~114.25.84. where 1 is version and 11, 25, 84 are all client ids which are accepted
 * 1~ without any client ids means refuse all
 *
 * euconsent-v2 example
 * accept all:
 * CO-Q1R-O-aRkpDeACBFRBECv_____3___wqIHKwAYAAgcqBysAGAAIHKgAA.f___7___4AA (f___7___4AA is encoded all ids from 1 to 917)
 * refuse all:
 * CO-Q1R-O-aRpsDeACBFRBECgAAAAAAAAAAqIHKwAAOVgAAAA.YAAAAAAAAAA (YAAAAAAAAAA will always be for refuse all)
 *
 * looks like CMP checkers only check the last part of the string.
 *
 * script usage:
 *
 * ```
 * import cmp_pv from "./cmp/index"
 * win.cmp_pv = cmp_pv;
 * cmp_pv.processCommand('init', 2, function(){}, {
 *        cookieDomain: null,
 *        cookieSecure: false,
 *   });
 * cmp_pv.cookie.saveConsent(false); //(to refuse all)
 * cmp_pv.cookie.saveConsent(true); //(to accept all)
 * ```
 **/
const _rangeVendor = [
    {
        name: 'maxVendorId', type: 'int', numBits: 16,
        default: function () {
            return parseInt(Object.keys(cmp_pv.globalVendorList.vendors).pop());
        }
    },
    {name: 'isRangeEncoding', type: 'int', numBits: 1, default: 0},
    {
        name: 'bitField', type: 'bits', numBits: function (obj) {
            return obj.maxVendorId;
        }, validator: function (obj) {
            return obj.isRangeEncoding === 0;
        }, default: function (obj) {
            return cmp_pv.consentString.defaultBits(true, obj.maxVendorId);
        }
    },
    {
        name: 'numEntries', type: 'int', numBits: 12, validator: function (obj) {
            return obj.isRangeEncoding === 1;
        }, default: 0
    },
    {
        name: 'rangeEntries', type: 'list', validator: function (obj) {
            return obj.isRangeEncoding === 1;
        }, listCount: function (obj) {
            return obj.numEntries;
        },
        fields: [
            {name: 'isARange', type: 'bool', numBits: 1},
            {name: 'startOrOnlyVendorId', type: 'int', numBits: 16},
            {
                name: 'endVendorId', type: 'int', numBits: 16, validator: function (obj) {
                    return obj.isARange;
                }
            }
        ]
    }
];

var cmp_pv = {
    /** Interface **/
    isLoaded: false,
    cmpReady: false,
    lastEvent: '',
    commandQueue: [],
    googleACList: [],
    processCommand: function (command, version, callback, parameter) {
        if (typeof cmp_pv.commands[command] !== 'function') {
            console.log('Invalid CMP command %s', command);
        }
        if (!cmp_pv.cmpReady && command !== 'init') {
            cmp_pv.commandQueue.push({
                command: command,
                version: version,
                parameter: parameter,
                callback: callback
            });
        } else {
            // console.info('Process command: %s, parameter: %s', command, parameter);
            cmp_pv.commands[command](parameter, callback);
        }
    },

    processCommandQueue: function () {
        var queue = this.commandQueue;
        if (queue.length) {
            // console.info('Process %d queued commands', queue.length);
            cmp_pv.commandQueue = [];
            for (var i = 0; i < queue.length; i++) {
                cmp_pv.processCommand(queue[i].command, queue[i].version, queue[i].callback, queue[i].parameter);
            }
        }
    },

    /** Configuration **/
    conf: {
        gdprApplies: true,
        hasGlobalScope: false,
        cookieDomain: 'inbox.lv',
        cookieSecure: true,
        // publisherName: 'inbox.lv',
        maxVendorId: 917, //hardcoded fallback max id at the time of script modification
        // urlVendorList: 'https://media.paruvendu.fr/vendor-list-v2.json?[RND]', //this works without CORS problems
        // urlVendorList: 'https://vendorlist.consensu.org/v2/vendor-list.json?[RND]', //this is original list, has CORS problems from our domains
        //actually we only need max id if we use accept all/refuse all
        // urlVendorList: 'https://m-portal37.indev.lv/assets/x.json?[RND]', //test
        // urlCookiesUsage: 'https://help.inbox.lv/?search=Cookie+Usage',
        dayCheckInterval: 30,
        // globalConsentLocation: 'https://paruvendu.mgr.consensu.org/portal.html',
        googleAC: true,
        //actual list: https://storage.googleapis.com/tcfac/additional-consent-providers.csv
        //we only need first column (vendor ids)
        // urlGoogleACList: 'https://media.paruvendu.fr/vendor-list-v0.json?[RND]',
        googleACList: [],
    },

    /** Commandes **/
    commands: {
        init: function (options) {
            // Options
            cmp_pv.conf = Object.assign(cmp_pv.conf, options);

            // Load consent
            cmp_pv.cookie.loadConsent(function (res) {
                // Not ready
                if (!res) {
                    cmp_pv.ui.show(true);
                } else {
                    // Ready
                    cmp_pv.cmpReady = true;
                    cmp_pv.processCommandQueue();
                    // Ask consent every X days if globalVendorList.version has changed
                    if (parseInt((new Date() - cmp_pv.cookie.lastVerification(cmp_pv.cookie.vendorCookieName)) / (24 * 3600 * 1000)) >= cmp_pv.conf.dayCheckInterval) {
                        cmp_pv._fetchGlobalVendorList(function () {
                            if (cmp_pv.globalVendorList.vendorListVersion !== cmp_pv.consentString.data.coreString.vendorListVersion) {
                                cmp_pv.ui.show(true);
                            }
                        });
                        // Update checked time
                        cmp_pv.cookie.saveVerification(cmp_pv.cookie.vendorCookieName);
                    } else {
                        //Fire tcloaded event
                        cmp_pv.event.send('tcloaded');
                    }
                }
            });
        },

        getTCData: function (vendorIds, callback) {
            var vendorList, vendorLIList;
            if (vendorIds && vendorIds.length) {
                vendorList = vendorLIList = {};
                for (var i = 0; i < vendorIds.length; i++) {
                    vendorList[vendorIds[i]] = cmp_pv.consentString.data.coreString.vendorConsent.bitField[vendorIds[i]];
                    vendorLIList[vendorIds[i]] = cmp_pv.consentString.data.coreString.vendorLegitimateInterest.bitField[vendorIds[i]];
                    if (typeof vendorList[vendorIds[i]] === 'undefined') vendorList[vendorIds[i]] = false;
                    if (typeof vendorLIList[vendorIds[i]] === 'undefined') vendorLIList[vendorIds[i]] = false;
                }
            } else {
                vendorList = cmp_pv.consentString.data.coreString.vendorConsent.bitField;
                vendorLIList = cmp_pv.consentString.data.coreString.vendorLegitimateInterest.bitField;
            }

            var consent = {
                tcString: cmp_pv.consentString.getConsentString(),
                tcfPolicyVersion: 2,
                cmpId: cmp_pv.consentString.const.CMP_ID,
                cmpVersion: cmp_pv.consentString.data.coreString.cmpVersion,

                /**
                 * true - GDPR Applies
                 * false - GDPR Does not apply
                 * undefined - unknown whether GDPR Applies
                 * see the section: "What does the gdprApplies value mean?"
                 */
                gdprApplies: cmp_pv.conf.gdprApplies,

                /**
                 * tcloaded
                 * cmpuishown
                 * useractioncomplete
                 */
                eventStatus: cmp_pv.lastEvent,

                /**
                 *
                 */
                cmpStatus: (cmp_pv.cmpReady) ? 'loaded' : 'loading',

                /**
                 * true - if using a service-specific or publisher-specific TC String
                 * false - if using a global TC String.
                 */
                isServiceSpecific: !cmp_pv.conf.hasGlobalScope,

                /**
                 * true - CMP is using publisher-customized stack descriptions
                 * false - CMP is NOT using publisher-customized stack descriptions
                 */
                useNonStandardStacks: false,

                /**
                 * Country code of the country that determines the legislation of
                 * reference.  Normally corresponds to the country code of the country
                 * in which the publisher's business entity is established.
                 */
                publisherCC: 'LV',

                /**
                 * Only exists on service-specific TC
                 *
                 * true - Purpose 1 not disclosed at all. CMPs use PublisherCC to
                 * indicate the publisher's country of establishment to help vVendors
                 * determine whether the vendor requires Purpose 1 consent.
                 *
                 * false - There is no special Purpose 1 treatmentstatus. Purpose 1 was
                 * disclosed normally (consent) as expected by TCF Policy
                 */
                purposeOneTreatment: false,

                /**
                 * Only exists on global-scope TC
                 */
                outOfBand: {
                    allowedVendors: {

                        /**
                         * true - Vendor is allowed to use and Out-of-Band Legal Basis
                         * false - Vendor is NOT allowed to use an Out-of-Band Legal Basis
                         */
                        //'[vendor id]': Boolean
                    },
                    disclosedVendors: {

                        /**
                         * true - Vendor has been disclosed to the user
                         * false - Vendor has been disclosed to the user
                         */
                        //'[vendor id]': Boolean
                    }
                },
                purpose: {
                    consents: cmp_pv.consentString.data.coreString.purposesConsent,
                    legitimateInterests: cmp_pv.consentString.data.coreString.purposesLITransparency
                },
                vendor: {
                    consents: vendorList,
                    legitimateInterests: vendorLIList
                },
                specialFeatureOptins: cmp_pv.consentString.data.coreString.specialFeatureOptIns,
                publisher: {
                    consents: cmp_pv.consentString.data.publisherTC.pubPurposesConsent,
                    legitimateInterests: cmp_pv.consentString.data.publisherTC.pubPurposesLITransparency,
                    customPurpose: {
                        consents: cmp_pv.consentString.data.publisherTC.customPurposesConsent,
                        legitimateInterests: cmp_pv.consentString.data.publisherTC.customPurposesLITransparency
                    },
                    restrictions: {
                        /*'[purpose id]': {

                            /!**
                             * 0 - Not Allowed
                             * 1 - Require Consent
                             * 2 - Require Legitimate Interest
                             *!/
                            '[vendor id]': 1
                        }*/
                    }
                }
            };

            if (cmp_pv.conf.googleAC) consent.addtlConsent = cmp_pv.consentString.data.acString;

            callback(consent, true);
        },

        addEventListener: function (_, callback) {
            cmp_pv.event.listenerId++;
            cmp_pv.event.listeners[cmp_pv.event.listenerId] = callback;
            cmp_pv.event.send((cmp_pv.ui.dom != null && cmp_pv.ui.dom.style.display === 'block') ? 'cmpuishown' : 'tcloaded', cmp_pv.event.listenerId);
        },

        removeEventListener: function (idEvent, callback) {
            delete cmp_pv.event.listeners[idEvent];
            callback(true);
        }
    },

    /** Events **/
    event: {
        listenerId: 0,
        listeners: {},
        send: function (eventStatus, listenerId) {
            if (typeof listenerId === 'undefined') listenerId = 0;
            // console.info('Listeners fired : ' + eventStatus + ' (' + listenerId + ')');
            cmp_pv.lastEvent = eventStatus;
            if (Object.keys(cmp_pv.event.listeners).length > 0) {
                cmp_pv.commands.getTCData(null, function (tcData, success) {
                    tcData.eventStatus = eventStatus;
                    for (var i in cmp_pv.event.listeners) {
                        i = parseInt(i);
                        if ((listenerId === 0 || i === listenerId) && typeof cmp_pv.event.listeners[i] === 'function') {
                            tcData.listenerId = i;
                            cmp_pv.event.listeners[i](tcData, success);
                        }
                    }
                });
            }
        }
    },

    //JM: this is severaly trimmed down UI module of original script. not removed fully to avoid accidentally breaking something
    /** UI **/
    ui: {
        dom: null,
        htmlOverflow: '',
        create: function (it) {
            if (typeof cmp_pv.globalVendorList === 'undefined') {
                cmp_pv._fetchGlobalVendorList(function () {
                    if (it < 2) cmp_pv.ui.create(++it);
                    else cmp_pv.ui.show(false);
                });
            } else {
                try {
                    if (typeof cmp_pv.consentString.data.coreString === 'undefined') cmp_pv.consentString.data = cmp_pv.consentString.generateConsentData();
                    // Update VendorList Version
                    cmp_pv.consentString.data.coreString.vendorListVersion = cmp_pv.globalVendorList.vendorListVersion;
                    // Fire cmpuishown event
                    cmp_pv.event.send('cmpuishown');
                } catch (e) {
                    console.error(e);
                    cmp_pv.ui.show(false);
                }
            }
        },
        show: function (bool) {
            if (cmp_pv.ui.dom === null) {
                if (bool) cmp_pv.ui.create(0);
            } else {
                // Fire cmpuishown event
                if (bool) {
                    cmp_pv.event.send('cmpuishown');
                    delete cmp_pv.consentString.data.tcString;
                }
            }
            return true;
        },
    },

    /** Cookie **/
    cookie: {
        vendorCookieName: 'euconsent-v2',
        _readCookie: function (name, cb) {
            var value = '; ' + document.cookie;
            var parts = value.split('; ' + name + '=');

            if (parts.length >= 2) {
                if (typeof cb === 'function') cb(parts.pop().split(';').shift());
                return parts.pop().split(';').shift();
            } else {
                if (typeof cb === 'function') cb('');
            }
        },
        _writeCookie: function (name, value, maxAgeSeconds, path, domain, secure) {
            var maxAge = maxAgeSeconds === null ? '' : ';max-age=' + maxAgeSeconds;
            var valDomain = domain === null ? '' : ';domain=' + domain;
            secure = (secure === null || secure === false) ? '' : ';secure';
            document.cookie = name + '=' + value + ';path=' + path + maxAge + valDomain + secure + ';samesite=lax;';
            this.saveVerification(name);
        },
        _readGlobalCookie: function (name, cb) {
            cmp_pv.portal.sendPortalCommand({
                command: 'readVendorConsent'
            }, function (data) {
                cb((typeof data === 'object') ? '' : data);
            });
        },
        _writeGlobalCookie: function (name, value) {
            cmp_pv.portal.sendPortalCommand({
                command: 'writeVendorConsent',
                encodedValue: value
            }, function () {
                cmp_pv.cookie.saveVerification(name);
            });
        },
        loadCookie: function (cb) {
            var fnct = (cmp_pv.conf.hasGlobalScope) ? '_readGlobalCookie' : '_readCookie';
            this[fnct](this.vendorCookieName, function (data) {
                cb(('undefined' !== typeof data) ? cmp_pv.consentString.decodeConsentString(data) : false);
            });
        },
        writeCookie: function () {
            var data = cmp_pv.consentString.generateConsentString();
            // console.log(['generated data', data]);
            var fnct = (cmp_pv.conf.hasGlobalScope) ? '_writeGlobalCookie' : '_writeCookie';
            this[fnct](this.vendorCookieName, data, 33696000, '/', cmp_pv.conf.cookieDomain, cmp_pv.conf.cookieSecure);
        },
        saveConsent: function (all) {
            // Shift dates
            cmp_pv.consentString.data.coreString.lastUpdated = new Date();
            cmp_pv.consentString.data.coreString.cmpId = cmp_pv.consentString.const.CMP_ID;

            // Accept everything
            if (typeof all != 'undefined') {
                var i;
                for (i = 1; i <= cmp_pv.consentString.data.coreString.vendorConsent.maxVendorId; i++) {
                    cmp_pv.consentString.data.coreString.vendorConsent.bitField[i] = all;
                    cmp_pv.consentString.data.coreString.vendorLegitimateInterest.bitField[i] = all;
                }
                /*for (i = 1; i <= cmp_pv.consentString.data.specific.vendorConsent.maxVendorId; i++) {
                    cmp_pv.consentString.data.specific.vendorConsent.bitField[i] = all;
                }*/
                for (i in cmp_pv.consentString.data.coreString.purposesConsent) {
                    cmp_pv.consentString.data.coreString.purposesConsent[i] = all;
                    cmp_pv.consentString.data.publisherTC.pubPurposesConsent[i] = all;
                    if (i > 1) cmp_pv.consentString.data.coreString.purposesLITransparency[i] = all;
                    if (i > 1) cmp_pv.consentString.data.publisherTC.pubPurposesLITransparency[i] = all;
                }
                for (i in cmp_pv.consentString.data.coreString.specialFeatureOptIns) {
                    cmp_pv.consentString.data.coreString.specialFeatureOptIns[i] = all;
                }
                var matches = document.querySelectorAll('#step2 input');
                for (i = 0; i < matches.length; i++) {
                    matches[i].checked = all;
                }
            }

            // Save cookies
            this.writeCookie();
            if (cmp_pv.conf.googleAC) this.saveGoogleAC(all);

            // Hide UI
            cmp_pv.ui.show(false);

            // Process commands
            cmp_pv.cmpReady = true;
            cmp_pv.processCommandQueue();

            // Fire useractioncomplete event
            cmp_pv.event.send('useractioncomplete');
        },
        loadConsent: function (cb) {
            var cb2;
            if (cmp_pv.conf.googleAC) {
                cb2 = function (data) {
                    cmp_pv.cookie.loadGoogleAC();
                    cb(data);
                };
            } else {
                cb2 = cb;
            }
            this.loadCookie(cb2);
        },
        saveVerification: function (name) {
            try {
                localStorage.setItem(name, new Date().toString());
            } catch (e) {
                //do nothing
            }
        },
        lastVerification: function (name) {
            try {
                var date = localStorage.getItem(name);
                return (date) ? Date.parse(date) : new Date();
            } catch (e) {
                return new Date();
            }
        },
        saveGoogleAC: function (all) {
            // Accept everything
            if (typeof all == 'undefined') {
                all = null;
            }
            // Create AC String
            var data = '1~';
            if (all === null || all === true) {
                for (var i = 0; i < cmp_pv.conf.googleACList.length; i++) {
                    // var id = cmp_pv.googleACList[i][0];
                    var id = cmp_pv.conf.googleACList[i]; //we use array of ints without additional google ac info for performance
                    if (all || cmp_pv.consentString.data.googleAC[id]) {
                        data += id + '.';
                    }
                    if (all) cmp_pv.consentString.data.googleAC[id] = all;
                }
            }
            cmp_pv.consentString.data.acString = data;
            try {
                localStorage.setItem('google-ac', data);
            } catch (e) {
                this._writeCookie('google-ac', data, 33696000, '/', cmp_pv.conf.cookieDomain);
            }
        },
        loadGoogleAC: function () {
            cmp_pv.consentString.data.googleAC = {};
            var string;
            try {
                string = localStorage.getItem('google-ac');
            } catch (e) {
                string = this._readCookie('google-ac');
            }
            if (string == null) return;

            var parts = string.split('~');
            if (parts.length === 2) {
                var ids = parts[1].split('.');
                cmp_pv.consentString.data.googleAC = {};
                for (var i = 0; i < ids.length; i++) {
                    cmp_pv.consentString.data.googleAC[ids[i]] = true;
                }
                cmp_pv.consentString.data.acString = string;
            }
        }
    },

    /** Consent String */
    consentString: {
        const: {
            VERSION_BIT_OFFSET: 0,
            VERSION_BIT_SIZE: 6,
            SEGMENT_BIT_SIZE: 3,
            SEGMENT_BIT_OFFSET: 0,
            CMP_ID: 222,
            CMP_VERSION: 2,

            coreString: [
                {name: 'version', type: 'int', numBits: 6, default: 2},
                {name: 'created', type: 'date', numBits: 36, default: new Date()},
                {name: 'lastUpdated', type: 'date', numBits: 36, default: new Date()},
                {
                    name: 'cmpId', type: 'int', numBits: 12, default: function () {
                        return cmp_pv.consentString.const.CMP_ID;
                    }
                },
                {
                    name: 'cmpVersion', type: 'int', numBits: 12, default: function () {
                        return cmp_pv.consentString.const.CMP_VERSION;
                    }
                },
                {name: 'consentScreen', type: 'int', numBits: 6, default: 1},
                {name: 'consentLanguage', type: '6bitchar', numBits: 12, default: 'LV'},
                {
                    name: 'vendorListVersion', type: 'int', numBits: 12, default: function () {
                        return cmp_pv.globalVendorList.vendorListVersion;
                    }
                },
                {
                    name: 'tcfPolicyVersion', type: 'int', numBits: 6, default: function () {
                        return cmp_pv.globalVendorList.tcfPolicyVersion;
                    }
                },
                {
                    name: 'isServiceSpecific', type: 'int', numBits: 1, default: function () {
                        return !cmp_pv.conf.hasGlobalScope;
                    }
                },
                {name: 'useNonStandardStacks', type: 'int', numBits: 1, default: 0},
                {
                    name: 'specialFeatureOptIns', type: 'bits', numBits: 12, default: function () {
                        return cmp_pv.consentString.defaultBits(false, this.numBits);
                    }
                },
                {
                    name: 'purposesConsent', type: 'bits', numBits: 24, default: function () {
                        return cmp_pv.consentString.defaultBits(false, this.numBits);
                    }
                },
                {
                    name: 'purposesLITransparency', type: 'bits', numBits: 24, default: function () {
                        var lit = cmp_pv.consentString.defaultBits(true, this.numBits);
                        lit[1] = false;
                        return lit;
                    }
                },
                {name: 'purposeOneTreatment', type: 'int', numBits: 1, default: 0},
                {name: 'publisherCC', type: '6bitchar', numBits: 12, default: 'LV'},
                {
                    name: ['vendorConsent', 'vendorLegitimateInterest'],
                    fields: function (name) {
                        _rangeVendor[2].default = function (obj) {
                            return cmp_pv.consentString.defaultBits(name === 'vendorLegitimateInterest', obj.maxVendorId);
                        };
                        return _rangeVendor;
                    }
                },
                {name: 'numPubRestrictions', type: 'int', numBits: 12, default: 0}/*,
				{
					name: 'pubRestrictions',
					type: 'list',
					listCount: function (obj) {
						return obj.numPubRestrictions;
					},
					fields: [
						{name: 'purposeId', type: 'int', numBits: 6, default: 0},
						{name: 'restrictionType', type: 'int', numBits: 2, default: 0},
					]
				}*/
            ],
            disclosedVendors: [ //TODO: OOB only global
                {name: 'segmentType', type: 'int', numBits: 3, default: 1},
                {
                    name: ['disclosedVendors'],
                    fields: _rangeVendor
                }
            ],
            allowedVendors: [ //TODO: OOB  only global
                {name: 'segmentType', type: 'int', numBits: 3, default: 2},
                {
                    name: ['allowedVendors'],
                    fields: _rangeVendor
                }
            ],
            publisherTC: [
                {name: 'segmentType', type: 'int', numBits: 3, default: 3},
                {
                    name: 'pubPurposesConsent', type: 'bits', numBits: 24, default: function () {
                        return cmp_pv.consentString.defaultBits(false, this.numBits);
                    }
                },
                {
                    name: 'pubPurposesLITransparency', type: 'bits', numBits: 24, default: function () {
                        var lit = cmp_pv.consentString.defaultBits(true, this.numBits);
                        lit[1] = false;
                        return lit;
                    }
                },
                {name: 'numCustomPurposes', type: 'int', numBits: 6, default: 0},
                {
                    name: 'customPurposesConsent', type: 'bits', numBits: function (obj) {
                        return obj.numCustomPurposes;
                    }, default: function (obj) {
                        return cmp_pv.consentString.defaultBits(false, obj.numberCustomPurposes);
                    }
                },
                {
                    name: 'customPurposesLITransparency', type: 'bits', numBits: function (obj) {
                        return obj.numCustomPurposes;
                    }, default: function (obj) {
                        return cmp_pv.consentString.defaultBits(false, obj.numberCustomPurposes);
                    }
                }
            ],
            // Other
            SIX_BIT_ASCII_OFFSET: 65
        },
        data: {},

        decodeConsentString: function (cookieValue) {
            var res = this.decodeCookieData(cookieValue);
            if (res) {
                this.data.tcString = cookieValue;
                /*if (typeof this.data.specific !== 'undefined') {
                    this.data.tcString = cookieValue.substr(0, cookieValue.lastIndexOf('.'));
                }*/
                var data = this.data['coreString'];
                var names = ['vendorConsent', 'vendorLegitimateInterest'];
                for (var z = 0; z < names.length; z++) {
                    var name = names[z];
                    if (data[name].isRangeEncoding === 1) {
                        var range, i, y;
                        // Initialize bitField
                        data[name].bitField = cmp_pv.consentString.defaultBits(false, data[name].maxVendorId);
                        // Assign range value
                        for (i = 0; i < data[name].rangeEntries.length; i++) {
                            range = data[name].rangeEntries[i];
                            // console.log('range.isARange: '+range.isARange);
                            if (range.isARange) {
                                for (y = range.startOrOnlyVendorId; y <= range.endVendorId; y++) {
                                    data[name].bitField[y] = true;
                                }
                            } else {
                                data[name].bitField[range.startOrOnlyVendorId] = true;
                            }
                        }
                    }
                }
            }
            return res;
        },
        decodeCookieData: function (cookieValue) {
            if (cookieValue === '') return false;
            var parts = cookieValue.split('.');
            for (var i = 0; i < parts.length; i++) {
                var bitString = this.decodeBase64UrlSafe(parts[i]);
                // console.log(['bitString: ', bitString]);
                var part = 'coreString';
                if (i > 0) {
                    var segmentType = this.decodeBitsToInt(bitString, this.const.SEGMENT_BIT_OFFSET, this.const.SEGMENT_BIT_SIZE);
                    // console.log('segmentType: '+segmentType);
                    switch (segmentType) {
                    case 1:
                        part = 'disclosedVendors';
                        break;
                    case 2:
                        part = 'allowedVendors';
                        break;
                    case 3:
                        part = 'publisherTC';
                        break;
                        /*case 4:
                            part = 'specific';
                            break;*/
                    default:
                        continue;
                    }
                } else {
                    var cookieVersion = this.decodeBitsToInt(bitString, this.const.VERSION_BIT_OFFSET, this.const.VERSION_BIT_SIZE);
                    if (typeof cookieVersion !== 'number') {
                        console.error('Could not find cookieVersion to decode');
                        return false;
                    }
                }

                this.data[part] = this.decodeConsentData(this.const[part], bitString, 0).obj;
                // console.log(['this.data[part]', this.data[part]]);

            }

            if (typeof this.data['publisherTC'] === 'undefined') this.data['publisherTC'] = this.generateData(this.const['publisherTC']);
            return true;
        },
        generateConsentString: function () {
            // CMP infos
            this.data.coreString.cmpId = cmp_pv.consentString.const.CMP_ID;
            this.data.coreString.cmpVersion = cmp_pv.consentString.const.CMP_VERSION;
            // Core
            var string = '';
            var names = ['vendorConsent', 'vendorLegitimateInterest'];
            var data = this.data.coreString;
            // console.log(['coreString', this.data.coreString]);
            // console.log(['names', names]);
            // console.log(['names.length', names.length]);

            for (var i = 0; i < names.length; i++) {
                var name = names[i];
                data[name] = Object.assign(data[name], this.convertVendorsToRanges(data, name));
                // Range test
                // console.log(['generateConsentString', name, Object.assign(data[name], {isRangeEncoding: 1})]);

                var inputBitsRange = this.encodeConsentData(_rangeVendor, Object.assign(data[name], {isRangeEncoding: 1}));
                var inputBits = this.encodeConsentData(_rangeVendor, Object.assign(data[name], {isRangeEncoding: 0}));
                data[name].isRangeEncoding = (inputBits.length > inputBitsRange.length) ? 1 : 0;
            }
            inputBits = this.encodeConsentData(this.const.coreString, data);
            string = this.encodeBase64UrlSafe(inputBits);

            // Publisher, Specific
            names = ['publisherTC'/*, 'specific'*/];

            for (i = 0; i < names.length; i++) {
                // console.log(['names make part ', names[i]]);
                inputBits = this.encodeConsentData(this.const[names[i]], this.data[names[i]]);
                // console.log(['names make part bits ', inputBits]);

                string += '.' + this.encodeBase64UrlSafe(inputBits);
                // console.log(['names make part string  ', this.encodeBase64UrlSafe(inputBits)]);
            }

            this.data.tcString = string.substr(0, string.lastIndexOf('.'));
            return string;
        },
        getConsentString: function () {
            if (typeof this.data.tcString == 'undefined') {
                this.generateConsentString();
            }

            return this.data.tcString;
        },
        padLeft: function (string, padding) {
            return this.repeat(Math.max(0, padding), '0') + string;
        },
        padRight: function (string, padding) {
            return string + this.repeat(Math.max(0, padding), '0');
        },
        repeat: function (count, string) {
            var padString = '';
            for (var i = 0; i < count; i++) {
                padString += string;
            }
            return padString;
        },
        decodeBitsToInt: function (bitString, start, length) {
            return parseInt(bitString.substr(start, length), 2);
        },
        decodeBitsToDate: function (bitString, start, length) {
            return new Date(this.decodeBitsToInt(bitString, start, length) * 100);
        },
        decodeBitsToBool: function (bitString, start) {
            return parseInt(bitString.substr(start, 1), 2) === 1;
        },
        decode6BitCharacters: function (bitString, start, length) {
            var decoded = '';
            var decodeStart = start;
            while (decodeStart < start + length) {
                decoded += String.fromCharCode(this.const.SIX_BIT_ASCII_OFFSET + this.decodeBitsToInt(bitString, decodeStart, 6));
                decodeStart += 6;
            }
            return decoded;
        },
        decodeBase64UrlSafe: function (value) {
            // Replace safe characters
            var unsafe = value
                .replace(/-/g, '+')
                .replace(/_/g, '/') + '=='.substring(0, (3 * value.length) % 4);
            var bitString = '';

            try {
                var bytes = atob(unsafe);
                for (var i = 0; i < bytes.length; i++) {
                    var bitS = bytes.charCodeAt(i).toString(2);
                    bitString += this.padLeft(bitS, 8 - bitS.length);
                }
            } catch (error) {
                console.error(error);
            }

            return bitString;
        },
        decodeConsentData: function (fields, bitString, start) {
            var obj = {};
            var i, z, y, field, length;
            var totalLength = 0;
            // console.log(['fields', fields]);
            for (i = 0; i < fields.length; i++) {
                field = fields[i];
                if (Array.isArray(field.name)) {
                    length = 0;
                    for (y = 0; y < field.name.length; y++) {
                        decodedObj = this.decodeConsentData(field.fields(), bitString, start + length);
                        obj[field.name[y]] = decodedObj.obj;
                        length += decodedObj.length;
                    }
                } else {
                    if ('function' === typeof field.validator && !field.validator(obj)) continue;
                    length = ('function' === typeof field.numBits) ? field.numBits(obj) : field.numBits;
                    switch (field.type) {
                    case 'int':
                        obj[field.name] = this.decodeBitsToInt(bitString, start, length);
                        break;
                    case 'date':
                        obj[field.name] = this.decodeBitsToDate(bitString, start, length);
                        break;
                    case '6bitchar':
                        obj[field.name] = this.decode6BitCharacters(bitString, start, length);
                        break;
                    case 'bool':
                        obj[field.name] = this.decodeBitsToBool(bitString, start);
                        break;
                    case 'bits':
                        z = 1;
                        obj[field.name] = {};
                        for (y = start; y < start + length; y++) {
                            obj[field.name][z] = this.decodeBitsToBool(bitString, y);
                            z++;
                        }
                        break;
                    case 'list':
                        var listCount = field.listCount(obj);
                        length = 0;
                        obj[field.name] = [];
                        for (z = 0; z < listCount; z++) {
                            var decodedObj = this.decodeConsentData(field.fields, bitString, start + length);
                            length += decodedObj.length;
                            obj[field.name].push(decodedObj.obj);
                        }
                        break;
                    default:
                        console.warn('Cookie definition field found without encoder or type: %s', field.name);
                    }
                }
                totalLength += length;
                start += length;
            }

            return {obj: obj, length: totalLength};
        },
        encodeIntToBits: function (number, numBits) {
            var bitString = '';

            if (typeof number === 'boolean') {
                number = number ? 1 : 0;
            }

            if (typeof number === 'number' && !isNaN(number)) {
                bitString = parseInt(number, 10).toString(2);
            }

            // Pad the string if not filling all bits
            if (numBits >= bitString.length) {
                bitString = this.padLeft(bitString, numBits - bitString.length);
            }

            // Truncate the string if longer than the number of bits
            if (bitString.length > numBits) {
                bitString = bitString.substring(0, numBits);
            }
            return bitString;
        },
        encodeBoolToBits: function (value) {
            return this.encodeIntToBits(value === true ? 1 : 0, 1);
        },
        encodeDateToBits: function (date, numBits) {
            if (date instanceof Date) {
                return this.encodeIntToBits(date.getTime() / 100, numBits);
            }
            return this.encodeIntToBits(date, numBits);
        },
        encode6BitCharacters: function (string, numBits) {
            var encoded = typeof string !== 'string' ? '' : string.split('').map(function (char) {
                var int = Math.max(0, char.toUpperCase().charCodeAt(0) - cmp_pv.consentString.const.SIX_BIT_ASCII_OFFSET);
                return cmp_pv.consentString.encodeIntToBits(int > 25 ? 0 : int, 6);
            }).join('');
            return this.padRight(encoded, numBits).substr(0, numBits);
        },
        encodeBase64UrlSafe: function (binaryValue) {
            // Pad length to multiple of 8
            var paddedBinaryValue = this.padRight(binaryValue, 7 - (binaryValue.length + 7) % 8);

            // Encode to bytes
            var bytes = '';
            for (var i = 0; i < paddedBinaryValue.length; i += 8) {
                bytes += String.fromCharCode(parseInt(paddedBinaryValue.substr(i, 8), 2));
            }

            // Make base64 string URL friendly
            return btoa(bytes)
                .replace(/\+/g, '-')
                .replace(/\//g, '_')
                .replace(/=+$/, '');
        },
        encodeConsentData: function (fields, datas) {
            var inputBits = '';
            for (var i = 0; i < fields.length; i++) {
                var field = fields[i];
                if (Array.isArray(field.name)) {
                    for (var x = 0; x < field.name.length; x++) {
                        inputBits += this.encodeConsentData(field.fields(), datas[field.name[x]]);
                    }
                } else {
                    // if ('function' === typeof field.fields) inputBits += this.encodeConsentData(field.fields(), datas);
                    if ('function' === typeof field.validator && !field.validator(datas)) continue;
                    var length = ('function' === typeof field.numBits) ? field.numBits(datas) : field.numBits;
                    switch (field.type) {
                    case 'int':
                        inputBits += this.encodeIntToBits(datas[field.name], length);
                        break;
                    case 'date':
                        inputBits += this.encodeDateToBits(datas[field.name], length);
                        break;
                    case '6bitchar':
                        inputBits += this.encode6BitCharacters(datas[field.name], length);
                        break;
                    case 'bool':
                        inputBits += this.encodeBoolToBits(datas[field.name]);
                        break;
                    case 'bits':
                        var data = datas[field.name];
                        for (var y = 1; y <= length; y++) {
                            inputBits += this.encodeBoolToBits(data[y]);
                        }
                        break;
                    case 'list':
                        for (var z = 0; z < datas[field.name].length; z++) {
                            inputBits += this.encodeConsentData(field.fields, datas[field.name][z]);
                        }
                        break;
                    default:
                        console.warn('Cookie definition field found without encoder or type: %s', field.name);
                    }
                }
            }
            return inputBits;
        },
        defaultBits: function (val, numBits) {
            var obj = {};
            for (var i = 1; i <= numBits; i++) {
                obj[i] = val;
            }
            return obj;
        },
        generateConsentData: function () {
            var obj = {};
            obj.coreString = this.generateData(this.const['coreString']);
            obj.publisherTC = this.generateData(this.const['publisherTC']);
            //obj.specific = this.generateData(this.const['specific']);
            if (cmp_pv.conf.googleAC) obj.googleAC = {};
            return obj;
        },
        generateData: function (fields) {
            var obj = {};
            for (var i = 0; i < fields.length; i++) {
                var field = fields[i];
                if (Array.isArray(field.name)) {
                    for (var y = 0; y < field.name.length; y++) {
                        obj[field.name[y]] = this.generateData(field.fields(field.name[y]));
                    }
                } else {
                    obj[field.name] = ('function' === typeof field.default) ? field.default(obj) : field.default;
                }
            }
            return obj;
        },
        convertVendorsToRanges: function (data, name) {
            var range = [];
            var rangeType = true;
            var ranges = {false: [], true: []};
            for (var id = 1; id <= data[name].maxVendorId; id++) {
                if (data[name].bitField[id] === rangeType) {
                    range.push(id);
                }
                // Range has ended or at the end of vendors list => add range entry
                if (data[name].bitField[id] !== rangeType || id === data[name].maxVendorId) {
                    if (range.length) {
                        var startOrOnlyVendorId = range.shift();
                        var endVendorId = range.pop();
                        range = [];
                        ranges[rangeType].push({
                            isARange: typeof endVendorId === 'number',
                            startOrOnlyVendorId: startOrOnlyVendorId,
                            endVendorId: endVendorId
                        });
                    }
                }
            }
            return {rangeEntries: ranges[rangeType], numEntries: ranges[rangeType].length};
        }
    },
    /** **/
    _fetchGlobalVendorList: function (callback) {

        //let's get max vendor id server side instead of ajax request
        const maxVendorId = 917;
        //stub vendor list so we don't need to download whole stuff
        cmp_pv.globalVendorList = {
            gvlSpecificationVersion: 2,
            vendorListVersion: 68,
            tcfPolicyVersion: 2,
            lastUpdated:    '2020-12-10T16:05:27Z',
            purposes: [],
            specialPurposes: [],
            features: [],
            specialFeatures: [],
            stacks: [],
            vendors:[]
        };
        cmp_pv.consentString.data.coreString = cmp_pv.consentString.generateData(cmp_pv.consentString.const['coreString']);
        cmp_pv.consentString.data.publisherTC = cmp_pv.consentString.generateData(cmp_pv.consentString.const['publisherTC']);
        cmp_pv.consentString.data.coreString.vendorConsent.maxVendorId = maxVendorId;
        cmp_pv.consentString.data.coreString.vendorLegitimateInterest.maxVendorId = maxVendorId;

        if (cmp_pv.conf.googleAC) {
            cmp_pv._fetchGoogleACList(callback);
        } else {
            callback();
        }
        //this time/resource consuming. we will get data directly from vendor/inbox/toolbarbundle/src/Service/CMPService.php directly
        // var dt = new Date();
        // cmp_pv._fetch(cmp_pv.conf.urlVendorList.replace('[RND]', dt.getFullYear() + dt.getMonth() + dt.getDate()), function (res) {
        //     try {
        //         if (res.status === 200) {
        //             cmp_pv.globalVendorList = JSON.parse(res.responseText);
        //             if (typeof cmp_pv.consentString.data.coreString !== 'undefined') {
        //                 // _rangeVendor[0].default();
        //                 var maxVendorId = parseInt(Object.keys(cmp_pv.globalVendorList.vendors).pop());
        //                 cmp_pv.consentString.data.coreString.vendorConsent.maxVendorId = maxVendorId;
        //                 cmp_pv.consentString.data.coreString.vendorLegitimateInterest.maxVendorId = maxVendorId;
        //             }
        //             //cmp_pv._fetchPubVendorList();
        //         } else {
        //             console.error("Can't fetch vendorlist: %d (%s)", res.status, res.statusText);
        //         }
        //     } catch (e) {
        //         //do nothing
        //     }
        //
        //     if (cmp_pv.conf.googleAC) {
        //         cmp_pv._fetchGoogleACList(callback);
        //     } else {
        //         callback();
        //     }
        // });
    },

    _fetchGoogleACList: function (callback) {

        //stub google ac list so we don't need to download whole stuff
        callback(); //JM: now we get ac ids server-side

        // var dt = new Date();
        // cmp_pv._fetch(cmp_pv.conf.urlGoogleACList.replace('[RND]', dt.getFullYear() + dt.getMonth() + dt.getDate()), function (res) {
        //     try {
        //         if (res.status === 200) {
        //             cmp_pv.googleACList = JSON.parse(res.responseText);
        //         } else {
        //             console.error("Can't fetch Google AC list: %d (%s)", res.status, res.statusText);
        //         }
        //     } catch (e) {
        //         //do nothing
        //     }
        //     callback();
        // });
    },

    // _fetch: function (url, callback) {
    //     try {
    //         var xhr = new XMLHttpRequest();
    //         xhr.onreadystatechange = function () {
    //             if (this.readyState === XMLHttpRequest.DONE) {
    //                 callback(this);
    //             }
    //         };
    //         xhr.open("GET", url, true);
    //         xhr.send();
    //     } catch (e) {
    //         callback({status: 500, statusText: e});
    //     }
    // }
};

export default cmp_pv;
