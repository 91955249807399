// BS5 Dropdown seems to work correctly, should consider removing this
// or removing logic that causes conflict with vanilla dropdown

/* global */
var win = require('window');

import { createPopper } from '@popperjs/core';


function gaTrackerSend(action) {
    if (win.ga) {
        win.ga('otherTracker.send', 'event', 'Toolbar', action, 'horizontal', 1);
    }
}

function resetList($container, $list) {
    $container.html($list);
}

function onWinResize($pBar) {
    var me    = this,
        $list = this.items;

    $pBar.css('visibility', 'hidden');
    resetList($pBar, $list);

    clearTimeout(win.resizedFinished);
    win.resizedFinished = setTimeout(function () {
        $pBar.css('visibility', 'visible');
        me.render();
        // console.log('Resized finished.');
    }, 50);
}

function getUnfoldDirection($container, $list) {
    // Distance of toolbar from viewport top minus header (100px)
    var scrollTop     = $(win).scrollTop(),
        elementOffset = $container.offset().top,
        distanceTop   = (elementOffset - scrollTop - 100),
        // Distance to bottom of viewport
        distanceBottom = $(win).height() - distanceTop - 150,
        // Height of dropdown
        dropdownMoreHeight = $list.innerHeight(),
        menuDirection = 'up';

    if ((distanceTop < dropdownMoreHeight) && (distanceTop < distanceBottom)) {
        menuDirection = 'down';
    }
    // console.dir(moreMenu);
    return menuDirection;
}

function toggleUnfoldDirection($moreContainer, $moreList) {
    var direction = getUnfoldDirection($moreContainer, $moreList);
    $moreContainer.toggleClass('go-down', direction === 'down');
}

function getVisibleItemsCount($container, $itemsList) {
    var counter       = 0,
        itemsCount    = $itemsList.length,
        ocuppiedWidth = 0,
        reserved      = 60,
        wrapperWidth  = $container.parent().width();
    do {
        var $item = $itemsList.eq(counter);
        ocuppiedWidth = Math.round($item.position().left + $item.width());
        if ((wrapperWidth - ocuppiedWidth) < reserved) {
            break;
        }
        counter++;
        // console.log(counter + '. Ocupied width: %s', ocuppiedWidth);
    } while (counter < itemsCount);
    // console.log('Container width %s', wrapperWidth);
    // console.info('visible: %s', counter);
    return counter;
}

function calculateMenuItems($container, list) {
    var visibleCount = getVisibleItemsCount($container, list),
        hiddenCount  = list.length - visibleCount,
        items = {};

    if (hiddenCount > 0) {
        items.hidden  = list.slice(visibleCount);
        items.visible = list.slice(0, visibleCount);
    }
    else {
        items.hidden  = [];
        items.visible = list;
    }
    return items;
}

export default (function () {
    var instance = null,
        $productBarContainer,
        $productBarList,
        $moreMenuContainer,
        $moreItemCount,
        $moreItemsList;

    var PBar = function (o) {
        $productBarContainer = $(o.prodListID);
        $productBarList      = $productBarContainer.children('ul');
        $moreMenuContainer   = $(o.moreMenuID);
        $moreItemCount       = $(o.moreItemsCountID);
        $moreItemsList       = $moreMenuContainer.children('ul');

        this.align = o.align || 'left';
        this.items = (function ($list) {
            return $list.children('li');
        }($productBarList));
        // Events handler
        $moreMenuContainer.on({
            'click': function () {
                toggleUnfoldDirection($moreMenuContainer, $moreItemsList);
            },
            'shown.bs.dropdown': function () {
                gaTrackerSend('Open');
            },
            'hidden.bs.dropdown': function () {
                gaTrackerSend('Close');
            }
        });
    };

    //todo we have dropdown issues in toolbar. now handling it manually
    function handleMiddleMenuDropdownClick() {
        document.addEventListener('click', function (event) {
            // console.log('handleMiddleMenuDropdownClick click called');
            // console.log(event.target);
            const closestEl = event.target.closest('.inx-product,.product-menu__more');
            if (!closestEl) {
                const shownMiddleMenuDropDown = document.querySelector('.inx-product .dropdown-menu.show');
                if (shownMiddleMenuDropDown) {
                    // console.log('click outside of menu dropdown, closing open dropdown');
                    shownMiddleMenuDropDown.classList.remove('show');
                }
                return;
            }
            const dropdownMenu = closestEl.querySelector('.dropdown-menu');
            // console.log('click inside middle menu, toggling showing menu dropdown');
            // closestEl.classList.toggle('show');

            //close other submenus to avoid 2 submenus open
            // if (closestEl.classList.contains('show')) {
            //     closestEl.parentNode.querySelectorAll('.inx-product.show,.product-menu__more.show').forEach((product) => {
            //         if (product !== closestEl) {
            //             product.classList.remove('show');
            //         }
            //     });
            // }

            if (closestEl.classList.contains('product-menu__more')) {
                if (closestEl.classList.contains('show')) {
                    dropdownMenu.scrollIntoView();
                }
            }

            //related to css reboot. probably not needed in html
            dropdownMenu.removeAttribute('hidden');
            createPopper(event.target.closest('[data-bs-toggle="dropdown"]'), dropdownMenu, {
                placement: 'top-end',
                modifiers: [
                    {
                        name: 'flip',
                        options: {
                            fallbackPlacements: ['top'],
                            padding: 15
                        }
                    },
                    {
                        name: 'offset',
                        options: {
                            offset: [10, 0]
                        }
                    },
                ]
            });

        }, false);
    }
    handleMiddleMenuDropdownClick();

    PBar.prototype.render = function () {
        var me           = this,
            currentItems = calculateMenuItems($productBarContainer, me.items),
            hiddenList, unfoldDirection;

        if (currentItems.hidden.length < 1 ) {
            $moreMenuContainer.addClass('inactive');
        }
        else {
            unfoldDirection = getUnfoldDirection($moreMenuContainer, $moreItemsList);
            hiddenList = (unfoldDirection !== 'down') ?
                currentItems.hidden.get().reverse()
                :
                currentItems.hidden;

            $moreMenuContainer.removeClass('inactive');
            $moreItemsList.html(hiddenList);
            $moreItemCount.text(currentItems.hidden.length);
            toggleUnfoldDirection($moreMenuContainer, $moreItemsList);
        }

        $productBarList
            .html(currentItems.visible)
            .addClass(this.align)
            .removeClass('not-ready');

        $(win).one('resize', function () {
            onWinResize.call(me, $productBarList);
        });

        return this;
    };

    return function (params) {
        if (instance === null) {
            instance = new PBar(params);
        }
        return instance;
    };
}());
