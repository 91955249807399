import '!style-loader!css-loader!./cmp.css';

export default ({ i18n, policyUrl }) => `
<div>
<div id="cmp-widget-wrapper">
    <div id="cmp-widget">
        <div class="cmp-popup">
            <table>
                <tbody>
                <tr>
                    <td class="cmp-types-content">
                        <div class="cmp-common">
                            <div class="cmp-common-text">
                                 ${i18n.cookiePolicyDescription}
                                 <a target="_blank" href="${policyUrl}">${i18n.cookiePolicy}</a>.
                            </div>
                        </div>
                        <!-- this is shown on View Options click-->
                        <label data-id="text-tech" class="cmp-2nd-screen">
                            <table>
                                <tbody>
                                <tr>
                                    <td class="cmp-checkbox-td">
                                        <div>
                                            <input type="checkbox" disabled="" checked class="cmp-checkbox-input">
                                            <div class="cmp-checkbox-styled"></div>
                                        </div>
                                    </td>
                                    <td class="cmp-checkbox-text-td">
                                        <div class="">
                                            ${i18n.essentialCookieDescription}
                                       </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </label>
                        <label data-id="text-analytical" class="cmp-2nd-screen">
                            <table>
                                <tbody>
                                <tr>
                                    <td class="cmp-checkbox-td">
                                        <div>
                                            <input type="checkbox" name="marketing_cookies" class="cmp-checkbox-input">
                                            <div class="cmp-checkbox-styled"></div>
                                        </div>
                                    </td>
                                    <td class="cmp-checkbox-text-td">
                                        <div class="">
                                            ${i18n.marketingCookieDescription}
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </label>
                        <label data-id="policy-text" class="cmp-2nd-screen">
                            <table>
                                <tbody>
                                <tr>
                                    <td></td>
                                    <td>
                                        <div class="">${i18n.readMore} <a
                                                target="_blank"
                                                href="${policyUrl}">${i18n.cookiePolicy}</a>.
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </label>
                    </td>

                    <td class="cmp-buttons">
                        <table class="cmp-buttons-table">
                            <tbody>
                            <tr>
                                <td class="cmp-buttons-table-td">
                                    <button data-text="${i18n.btn.acceptSelected}" data-id="button-tech"
                                            class="cmp-buttons-table-td-btn cmp-buttons-table-td-btn-options">
                                            ${i18n.btn.options}
                                    </button>
                                </td>
                                <td class="cmp-buttons-table-td">
                                    <button data-text="${i18n.btn.acceptAll}" data-id="button-all"
                                            class="cmp-buttons-table-td-btn cmp-buttons-table-td-btn-accept">
                                        ${i18n.btn.accept}
                                    </button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <div><div></div></div>
    </div>
</div>
</div>`;
