export default (params) => {
    const { i18n, promoID, theme } = params;
    const modalClassName = 'promo-modal';
    const className = [
        modalClassName,
        `${modalClassName}_theme-${theme}`,
        params.isXSDevice ? '' : `${modalClassName}_center-vertical`
    ].join(' ');

    // Template based on bootstrap 5 modal
    return `
        <div class="modal ${className} fade" id="${promoID}" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header" style="border:0;padding:0 !important">
                        <button class="btn-close" data-bs-action="close" data-action="close" title="${i18n.close}" type="button">
                        </button>
                    </div>
                    <div class="modal-body">
                        <h3 style="border-bottom:1px solid #e5e5e5;font-size:28px;font-weight:bold;margin:0 0 14px 0;padding-bottom:14px">${i18n.body[0]}</h3>
                        <p style="line-height:1.2;text-align:justify">${i18n.body.slice(1).join(' ')}</p>
                    </div>
                    <div class="modal-footer" style="font-size:16px">
                        <button class="modal-btn modal-btn_primary w-100" data-action="upgrade" type="button">${i18n.txt1}</button>
                        <button class="modal-btn modal-btn_default" data-action="later" type="button">${i18n.txt2}</button>
                    </div>
                </div>
            </div>
        </div>
    `;
};
