import cmp_pv from './cmp-lib';
import cmpWidget from './cmp-widget';
import bisquit from '../../utils/cookie-handler';

// console.log('cmp loaded!');
export default () => {
    // console.log('cmp started!');
    const getMainDomain = () => {
        const domain_parts = location.hostname.split('.');
        const last_part = domain_parts.pop();
        const before_last = domain_parts.pop();

        return before_last + '.' + last_part;
    };

    window.cmp_pv = cmp_pv;
    window.__tcfapi = cmp_pv.processCommand; //I don't know it it is needed. this is some kind of CMP api other providers use?
    cmp_pv.processCommand('init', 2, function(){}, {
        cookieDomain: getMainDomain(),
        cookieSecure: false,
        maxVendorId: window.inx.cmp.maxVendorId,
        googleACList: window.inx.cmp.googleACList,
    });

    //on login create _inbox_gdpr cookie.
    // if cookie exists and GDPR accepted do not show modal even if logged out (non-session cookie).
    //if privacy is declined always show cmp banner after logged in
    const gdprAcceptValue = parseInt(bisquit.getIt('_inbox_gdpr'));
    // console.log(['gdprAcceptValue: ', gdprAcceptValue]);
    if (gdprAcceptValue === window.inx.cmp.gdprAccepted) {
        //no need to show consent UI but immediately accept
        cmp_pv.cookie.saveConsent(true);
    } else if (window.inx.cmp.showWidget) {
        //CMP stuff start
        const euConsentCookieValue = bisquit.getIt('euconsent-v2');

        const showCMPWidgetUI = () => {
            //show widget if we don't have eu consent cookie and current page is not mobile login/signup
            return !euConsentCookieValue && window.location.pathname.indexOf('/oauth2/') === -1;
        };

        if (showCMPWidgetUI()) {
            //GDPR declined or no GDPR cookie
            const onAcceptCallback = () => cmp_pv.cookie.saveConsent(true);
            const onRefuseCallback = () => cmp_pv.cookie.saveConsent(false);
            //init CMP UI
            cmpWidget(onAcceptCallback, onRefuseCallback);
        } else {
            //if we have euconsent cookie- re-actualize cookie data
            // (for example localstorage can be lost in other subdomain so we need to re-build localstorage data)
            if (euConsentCookieValue) {
                //refuse all always ends with .YAAAAAAAAAA
                if (euConsentCookieValue.endsWith('.YAAAAAAAAAA')) {
                    cmp_pv.cookie.saveConsent(false);
                } else {
                    cmp_pv.cookie.saveConsent(true);
                }
            }
        }
    }
};
