import { DOMAINS_LIST } from './config';

const getLogo = ((domainsList) => (domain) => {
    const
        selected = domainsList.find((item) => item === domain) || 'lv';
    return require(`src/img/product-logos/go_white_${selected}.png`);
})(DOMAINS_LIST);

export default (params) => {
    // console.dir(params);
    const
        modalClassName = 'promo-modal',
        {
            i18n,
            domain,
            promoID,
            theme
        } = params,
        className = [
            modalClassName,
            `${modalClassName}_theme-${theme}`,
            params.isXSDevice ? '' : `${modalClassName}_center-vertical`
        ].join(' '),
        logoFullPath = (theme === 'default')
            ? params.defaultLogoPath
            : [params.sharedAssetsPath, getLogo(domain)].join('');
    // Template based on bootstrap 3 modal
    return `
        <div class="modal ${className} fade" id="${promoID}" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="btn-close" data-bs-action="close" data-action="close" title="${i18n.close}" type="button">
                        </button>
                        <img class="modal-logo m-auto" src="${logoFullPath}" title="${i18n.txt1}">
                    </div>
                    <div class="modal-body">
                        <p>${i18n.body}</p>
                    </div>
                    <div class="modal-footer">

                        <div class="col-12 d-flex w-100">
                             <button class="col-6 modal-btn modal-btn_primary" data-action="upgrade" type="button">${i18n.txt1}</button>
                             <div class="col-1" style="padding-left:3px;padding-right:3px">
                                <p class="text-center" style="padding-top:6px">${i18n.or}</span>
                             </div>
                             <button class="col-5 modal-btn modal-btn_primary purchase_gift" data-action="gift" type="button">${i18n.txt1_2}</button>
                        </div>

                        <button class="modal-btn modal-btn_default w-100" data-action="later" type="button">${i18n.txt2}</button>
                        <button class="modal-btn modal-btn_link" data-action="dontShowAgain" type="button">${i18n.txt3}</button>
                    </div>
                </div>
            </div>
        </div>
    `;
};
