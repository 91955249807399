import { DEFAULTS } from './config';
import { getDeviceGroup, getRndHash } from '../../utils';
import biscuit from '../../utils/cookie-handler';
import mailPromoTpl from './template_mailPromo';
import blockedAdsTpl from './template_blockedAds';
import {Modal} from 'bootstrap';

export const MODAL_TYPE_MAIL_PROMO = 1;
export const MODAL_TYPE_ADBLOCK = 2;

const instance = {}; // Class instance
const pvt = new WeakMap(); // Holds private data of the class

function getExpiryTime(secs) {
    const nowDate = new Date();
    nowDate.setTime(nowDate.getTime() + (secs * 1000));
    return nowDate;
}

function getPurchaseUrl(clickFn, purchaseUrl, clickTagID) {
    return (typeof clickFn === 'function') ? clickFn() + clickTagID : purchaseUrl;
}

function reguestPixel(fn, param) {
    if (typeof (fn) === 'function') {
        fn(param);
        return true;
    }
    return false;
}

function setCookie(type) {
    const { cookieName, ttl } = pvt.get(this);

    if (type) {
        // "Don't show again" uses the same cookie ttl as "Close"
        type = (type === 'dontShowAgain') ? 'close' : type;
        biscuit.setIt(cookieName, 1, getExpiryTime(ttl[type]));
    }
}

function getModal(params, inxBX) {
    const { modalType = MODAL_TYPE_MAIL_PROMO, ...rest } = params;
    const { clickTagID, pixelIDS, clickTagIDs = {} } = params.tracking;
    const $modal = $(modalType === MODAL_TYPE_MAIL_PROMO
        ? mailPromoTpl(rest)
        : blockedAdsTpl(rest)
    );
    const me = this;

    if (!$modal || !$modal[0]) {
        console.error('modal could not be retrieved');
    }

    const modalEl = $modal[0];

    document.body.appendChild(modalEl);

    const myModal = new Modal(modalEl, {
        backdrop: 'static',
        keyboard: false,
        show: false
    });


    modalEl.addEventListener('shown.bs.modal', () => {
        pvt.set(me, {
            ...pvt.get(me),
            isModalVisible: true
        });
    });

    modalEl.addEventListener('hidden.bs.modal', () => {
        pvt.set(me, {
            ...pvt.get(me),
            isModalVisible: false
        });
    });
    $('#'+modalEl.id)
        .on('click', 'button, .modal-logo', function () {
            let mailPurchaseUrl = params.mailPurchaseUrl;

            if ($(this).hasClass('purchase_gift')) {
                mailPurchaseUrl = mailPurchaseUrl + '&user_choose=gift';
            }

            const { action = false } = $(this).data();

            // Click from one of "not upgrade" buttons
            if (Object.prototype.hasOwnProperty.call(pixelIDS, action)) {
                params.setCookie && setCookie.call(me, action);
                me.hide();
                reguestPixel(inxBX.bxUViews, pixelIDS[action]);
            } else if (Object.prototype.hasOwnProperty.call(clickTagIDs, action)) {
                // Redirect user to the purchase
                location.href = getPurchaseUrl(inxBX.clickUrl, mailPurchaseUrl, clickTagIDs[action]);
            } else {
                // Click from 'upgrade' button # legacy fallback as before there was only one link
                location.href = getPurchaseUrl(inxBX.clickUrl, mailPurchaseUrl, clickTagID);
            }
        });

    return myModal;
}

class MailPromo {
    constructor(params, inxBX) {
        pvt.set(this, {
            ...params,
            $modal: getModal.call(this, params, inxBX)
        });
    }

    hide() {
        const { $modal } = pvt.get(this);
        if ($modal.length < 1) {
            return;
        }
        $modal.hide();
    }

    show() {
        const { $modal, cookieName, isModalVisible } = pvt.get(this);
        if (biscuit.hasIt(cookieName) || isModalVisible) {
            return;
        }
        $modal.show();
    }
}

export default (paths) => {
    return (inxBX, props) => {
        const
            config = DEFAULTS(),
            defaults = {
                ...config,
                isXSDevice: getDeviceGroup() === 'xs',
                setCookie: true,
                promoID: `${(props && props.cookieName) || config.cookieName}_${getRndHash(6)}`,
                ...paths
            };
        props = props ? { ...defaults, ...props } : defaults;

        if (!pvt.get(instance)) {
            pvt.set(instance, new MailPromo(props, inxBX));
        }

        return pvt.get(instance);
    };
};
