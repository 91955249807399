/* global INBOX */
const defaults = {
    badgeRecent: {
        containerSelector: '.incoming_mail_link, .btn_i3x-side-menu .num-wrap, .product-mail .badge-wrap',
        title: 'Recent messages',
        selector: '.num-recent',
        bgColor: '#e13828' // Replace with imported constant when IE11 will RIP
    },
    badgeUnread: {
        containerSelector: '.unread_mail_link, .product-mail .badge-wrap',
        title: 'Unread messages',
        selector: '.num-unread',
        bgColor: '#2d73d0' // Replace with imported constant when IE11 will RIP
    }
};

function getAtag({ title, link }) {
    return (text) => `<a class="badge-link" href="${link}" title="${title}">${text}</a>`;
}

function getTemplate(opts) {
    const
        { bgColor, selector } = opts,
        type = selector.slice(1),
        aTag = (opts.link !== undefined) ? getAtag(opts) : null;

    return (count) => {
        const content = (typeof(aTag) === 'function') ? aTag(count) : count;
        return `
            <span class="badge ${type}" style="background-color:${bgColor}">
                ${content}
            </span>`;
    };
}

function prepareBadges(opts) {
    const tpls = {};

    for (let badge of Object.keys(opts)) {
        const name = opts[badge].selector.slice(1);
        tpls[name] = getTemplate(opts[badge]);
    }

    return {
        hasBadge(badgeSelector, $container) {
            return $(badgeSelector, $container).length;
        },
        set(count, badgeOpts, $container) {
            const { selector } = badgeOpts;

            if (count > 0) {
                const
                    name     = selector.slice(1),
                    number   = (count > 99) ? '99+' : count,
                    newBadge = tpls[name](number);
                // console.log(this.hasBadge(selector, $container));
                if (this.hasBadge(selector, $container)) {
                    $(selector, $container).replaceWith(newBadge);
                }
                else {
                    $container.append(newBadge);
                }
            }
            else {
                $(selector, $container).remove();
            }
        }
    };
}

function prepareRendering(opts, badgeContainers) {
    const
        { badgeRecent, badgeUnread } = opts,
        $containers   = $(badgeContainers),
        $badgesRecent = $(badgeRecent.containerSelector, $containers),
        $badgesUnread = $(badgeUnread.containerSelector, $containers),
        badge = prepareBadges(opts);

    return function (evt, { recent, unread }) {
        // console.log('There are %s new and %s unread messages', recent, unread);
        badge.set(recent, badgeRecent, $badgesRecent);
        badge.set(unread, badgeUnread, $badgesUnread);
    };
}

export default function(opts) {
    // console.dir(opts);
    const
        { i18n, links, selectors } = opts,
        { badgeRecent, badgeUnread } = defaults;

    badgeRecent.title = i18n.recent;
    badgeRecent.link  = links.recentURL;
    badgeUnread.title = i18n.unread;
    badgeUnread.link  = links.unreadURL;

    const renderBadges = prepareRendering(defaults, selectors);

    INBOX.observer.observe('mailDataFetched', renderBadges);
}
