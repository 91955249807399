import $ from 'jquery';
import SunCalc from 'suncalc';

/** Get weather info from api.met.no (yr.no)
 * Weather api documentioation: http://api.met.no/weatherapi/locationforecastlts/1.2/documentation
 */
// const testingData = { 'weather': { 'currTemp': -14.4, 'windDir': 'ENE', 'windSpeed': 3.4, 'icoSymbol': 'PartlyCloud' }, 'geoip': { 'country_name': 'Latvia', 'city': 'Riga', 'latitude': 56.950000762939, 'longitude': 24.10000038147 } };

function render(selector, data, i18n) {
    // data = testingData;
    const
        {
            geoip,
            weather
        } = data,
        {
            currTemp = null,
            icoSymbol = null,
            windDir = null,
            windSpeed = null
        } = weather,
        hasAllValues = Object.keys(weather).every(key => weather[key] !== null),
        $weather = $(selector);

    if ($weather.length < 1) {
        return;
    }
    // Handle case when json is received, but current weather coditions contains "null" values
    if (!hasAllValues) {
        return;
    }
    // console.log(geoip);
    const
        icoName    = icoSymbol.toLowerCase(),
        icoText    = icoSymbol.replace(/([A-Z])/g, ' $1').trim(),
        now        = new Date(),
        times      = SunCalc.getTimes(now, geoip.latitude, geoip.longitude),
        isDayTime  = (now < times.sunset && now > times.sunrise) ? true : false,
        weatherUrl = `https://www.yr.no/en/forecast/daily-table/${geoip.latitude},${geoip.longitude}`;
    // Calculcate date/night with SunCalc
    // var sunriseStr = times.sunrise.getHours() + ':' + times.sunrise.getMinutes();
    // var sunsetStr = times.sunset.getHours() + ':' + times.sunset.getMinutes();

    $weather
        .show()
        .attr('href', weatherUrl)
        .find('.weather__city').text(geoip.city.replace('Riga', 'Rīga')).end() // @todo translate js side or server side
        .find('.weather__temp').html(`${currTemp}&#8451;`).end()
        .find('.weather__wind').text(`${i18n.directions[windDir]} ${i18n.wind} ${windSpeed} ${i18n.speed}`).end()
        .find('.ico-meteo')
        .attr('title', icoText)
        // For the night add suffix "-moon".
        .addClass(`imi-${icoName} imi-${icoName}${isDayTime ? '' : '-moon'}`);
}

export default function(selector, params, i18n) {
    if (!params) {
        return;
    }
    if (params.apiUrl === undefined) {
        return;
    }
    if (params.enabled && params.enabled === false) {
        return;
    }
    // Get weather json
    $.ajax({
        type:     'GET',
        url:      params.apiUrl, // Defined in head_assets template
        dataType: 'json',
        success: (data) => render(selector, data, i18n)
    });
}
