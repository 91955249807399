import { doAjax } from 'src/js/utils';
import { getMapiUrl, processItems } from './utils';
import templates from './templates';

const renderAccounts = ({ args, container, inxClasses }) => {
    // console.log(args);
    const imgIdBase = 'account__avatar-';
    const { i18n, linkedAccounts, urlParts } = args;
    const addAcountHtml = templates.get('addAcount')(urlParts[0], i18n);

    if (linkedAccounts.length < 1) {
        container.innerHTML = addAcountHtml;
        return;
    }

    const { accountsList, imgIdsList, itemsList } = processItems(linkedAccounts, urlParts, imgIdBase);
    container.innerHTML = templates.get('itemsList')(itemsList) + addAcountHtml;

    linkedAccounts.forEach((account, idx) => {
        const avatarInstance = inxClasses.Avatar(`#${imgIdsList[idx]}`, accountsList[idx]);
        avatarInstance.check();
    });

    // Check recent
    doAjax({ url: getMapiUrl(args.mainUser.email, args.mapiHost) })
        .then((response) => {
            response.linkedUsers.forEach((user) => {
                const { hasRecent, username } = user;
                const indicator = container.querySelector(`[data-user="${username}"]`);
                indicator.classList.toggle('on', hasRecent);
            });
        })
        .catch((/* err */) => {
            // console.log(err);
        });
};

export default (obj, INBOX) => {
    // console.log(obj);
    // Lazy load linked account images - only when sidebar is opened
    INBOX.observer.observe('sideMenuOpen', (e, isOpen) => {
        const { containerId, ...args } = obj;
        const container = document.getElementById(containerId);

        if (!container || !isOpen) return;

        renderAccounts({ args, container, inxClasses: INBOX.classes });
    });
};
